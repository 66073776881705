<template>
  <div class="Gstreaming">
	 <img src="../../assets/imges/index/25.png" class="headerimg" />
    <div class="streamingsystem">
      <div class="header">
        <div class="title">5G直播系统</div>
        <div class="info">APP、小程序、公众号、H5多端适用</div>
      </div>
      <div
        style="display: flex; justify-content: space-between"
        class="streamingsystemlist"
      >
        <div class="streamingsystemcard">
          <div class="cardimg">
            <img src="../../assets/5Gstreaming/applet.png" />
          </div>
          <div
            style="display: inline-block; vertical-align: top"
            class="streamingsysteminfo"
          >
            <div class="title">微信小程序直播</div>
            <div class="info">
              轻量化小程序直播，免流量、免资质，快速介入、降低直播带货门槛
            </div>
          </div>
          <div class="bebottom"></div>
        </div>
        <div class="streamingsystemcard">
          <div class="cardimg">
            <img src="../../assets/5Gstreaming/OfficialAccounts.png" />
          </div>
          <div
            style="display: inline-block; vertical-align: top"
            class="streamingsysteminfo"
          >
            <div class="title">公众号电商直播</div>
            <div class="info">
              适用于平台化运营，通过搭配云店奖励，打造万店同源直店，快速裂变
            </div>
          </div>
          <div class="bebottom"></div>
        </div>
        <div class="streamingsystemcard">
          <div class="cardimg">
            <img src="../../assets/5Gstreaming/cloud.png" />
          </div>
          <div
            style="display: inline-block; vertical-align: top"
            class="streamingsysteminfo"
          >
            <div class="title">生态云直播</div>
            <div class="info">
              适用于生活服务类直播，通过多主播分享带货，实现在线售货购买
            </div>
          </div>
          <div class="bebottom"></div>
        </div>
      </div>
    </div>

    <div class="scheme">
      <div class="header">
        <div class="title" style="color: #fff">直播电商带货方案</div>
        <div class="info" style="color: #fff">
          多种应用搭配，构建线上线下营销闭环
        </div>
      </div>
      <img src="../../assets/5Gstreaming/flow.png" />
    </div>

    <div class="productlist">
      <div class="productinfo1">
        <div class="productlistcard">
          <div class="productlistinfo">
            <img src="../../assets/5Gstreaming/1.png" />
            <div class="title">限时折扣</div>
            <div class="info">限时折扣</div>
            <div class="info">限时秒杀</div>
            <div class="info">购买</div>
          </div>
        </div>
        <div class="productlistcard">
          <div class="productlistinfo">
            <img src="../../assets/5Gstreaming/2.png" />
            <div class="title">商品拼团</div>
            <div class="info">分享拼团</div>
            <div class="info">好友拼团</div>
            <div class="info">享折扣</div>
          </div>
        </div>
        <div class="productlistcard">
          <div class="productlistinfo">
            <img src="../../assets/5Gstreaming/3.png" />
            <div class="title">礼物打赏</div>
            <div class="info">红包打赏</div>
            <div class="info">礼物打赏</div>
            <div class="info">兑换商品</div>
          </div>
        </div>
        <div class="productlistcard" style="border-right: 1px solid #f5f5f9">
          <div class="productlistinfo">
            <img src="../../assets/5Gstreaming/4.png" />
            <div class="title">直播抽奖</div>
            <div class="info">评论抽奖</div>
            <div class="info">限时抽奖</div>
            <div class="info">抽奖送礼</div>
          </div>
        </div>
      </div>

      <div class="productinfo2">
        <div class="productlistcard">
          <div class="productlistinfo">
            <img src="../../assets/5Gstreaming/5.png" />
            <div class="title">直播推流</div>
            <div class="info">微信直播</div>
            <div class="info">APP直播</div>
            <div class="info">OBS推流</div>
          </div>
        </div>
        <div class="productlistcard">
          <div class="productlistinfo">
            <img src="../../assets/5Gstreaming/6.png" />
            <div class="title">线下倒流</div>
            <div class="info">预付定金</div>
            <div class="info">门店自取</div>
            <div class="info">节省时间</div>
          </div>
        </div>
        <div class="productlistcard">
          <div class="productlistinfo">
            <img src="../../assets/5Gstreaming/7.png" />
            <div class="title">商品橱窗</div>
            <div class="info">直播商品</div>
            <div class="info">礼包商品</div>
            <div class="info">博主店铺</div>
          </div>
        </div>
        <div class="productlistcard" style="border-right: 1px solid #f5f5f9">
          <div class="productlistinfo">
            <img src="../../assets/5Gstreaming/8.png" />
            <div class="title">更多</div>
            <div class="info">持续更新中</div>
            <div class="info">……</div>
          </div>
        </div>
      </div>
    </div>

    <div class="cause">
      <div class="header">
        <div class="title">为什么选择5G直播</div>
      </div>
      <div class="causecardlist">
        <div class="causecardinfo1">
          <div class="causecard">
            <img src="../../assets/5Gstreaming/blue1.png" />
            <div class="title">给解决方案</div>
            <div class="info">免费为企业提供诊断咨询，并输出完整的解决方案</div>
          </div>
          <div class="causecard">
            <img src="../../assets/5Gstreaming/blue2.png" />
            <div class="title">运营有保障</div>
            <div class="info">
              百人运营团队做后盾，扶持应用快速上线，免费迭代升级
            </div>
          </div>
          <div class="causecard">
            <img src="../../assets/5Gstreaming/blue3.png" />
            <div class="title">建生态体系</div>
            <div class="info">
              可打通5G直播各大应用体系，便于建生态，拓展产业链
            </div>
          </div>
        </div>
        <div class="causecardinfo2">
          <div class="causecard">
            <img src="../../assets/5Gstreaming/blue4.png" />
            <div class="title">多用户端口</div>
            <div class="info">
              可打通APP、小程序、H5、公众号等端口，灵活布控市场
            </div>
          </div>
          <div class="causecard">
            <img src="../../assets/5Gstreaming/blue5.png" />
            <div class="title">多销售模式</div>
            <div class="info">
              支持源码和Saas销售模式，企业可灵活招商，掌控数据
            </div>
          </div>
          <div class="causecard">
            <img src="../../assets/5Gstreaming/blue6.png" />
            <div class="title">关系可享用</div>
            <div class="info">
              可与多个应用灵活搭配，且用户关系可互通，快速裂变
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.Gstreaming {
  text-align: center;

  .streamingsystem {
    padding: 92px 260px;

    .streamingsystemlist {
      text-align: left;
      margin-top: 57px;

      .streamingsystemcard:hover {
        .bebottom {
          border-radius: 0 0 20px 20px;
          width: 338px;
          height: 17px;
          position: absolute;
          margin-top: 198px;
          margin-left: 28px;
          background-color: #2f7ffc;
        }
      }

      .streamingsystemcard {
        width: 388px;
        height: 146px;
        box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.13);
        border-radius: 10px;
        border: 1px solid #e8e8e8;
        padding: 50px 24px;
        display: flex;

        .cardimg {
          width: 86px;
          height: 86px;

          padding-top: 14px;
          img {
            width: 86px;
          }
        }

        .streamingsysteminfo {
          margin-left: 32px;

          .title {
            font-size: 24px;
            font-family: Medium;
            font-weight: 500;
            color: #2e7df9;
          }

          .info {
            margin-top: 15px;
            font-size: 20px;
            font-family: Regular;
            font-weight: 400;
            color: #9e9e9e;
            // line-height: 32px;
          }
        }
      }
    }
  }

  .scheme {
    background: #2f7ffc;
    padding: 92px 260px;

    img {
      width: 100%;
      padding-top: 52px;
    }
  }

  .productlist {
    .productinfo1 {
      padding: 234px 260px 0 260px;
      background: #f5f5f9;
      display: flex;
      justify-content: center;
      margin-bottom: 0;

      .productlistcard {
        border-right: 1px solid #e6e6e6;
        border-bottom: 1px solid #e6e6e6;
        padding-bottom: 44px;
        .productlistinfo {
          margin: 0 130px;
          width: 98px;
        }

        img {
          width: 63px;
        }

        .title {
          font-size: 24px;
          font-family: Medium;
          font-weight: 500;
          margin-top: 26px;
          margin-bottom: 23px;
        }

        .info {
          font-size: 14px;
          font-family: Medium;
          font-weight: 500;
          color: #9e9e9e;
          line-height: 21px;
          margin-top: 15px;
        }
      }
    }

    .productinfo2 {
      padding: 0px 260px 91px 260px;
      background: #f5f5f9;
      display: flex;
      justify-content: center;

      .productlistcard {
        border-right: 1px solid #e6e6e6;

        .productlistinfo {
          margin: 0 130px;
          margin-top: 71px;
          width: 98px;
        }

        img {
          width: 63px;
        }

        .title {
          font-size: 24px;
          font-family: Medium;
          font-weight: 500;
          margin-top: 26px;
          margin-bottom: 23px;
        }

        .info {
          font-size: 14px;
          font-family: Medium;
          font-weight: 500;
          color: #9e9e9e;
          line-height: 21px;
          margin-top: 15px;
        }
      }
    }
  }

  .cause {
    margin: 104px auto;
    width: 80%;
    .causecardlist {
      .causecardinfo1 {
        margin-top: 140px;
        display: flex;
        justify-content: space-between;
        .causecard {
          width: 262px;
          height: 156px;
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.13);
          border-radius: 10px;
          border: 1px solid #e8e8e8;
          padding: 45px 85px;
          .title {
            font-size: 24px;
            font-family: Medium;
            font-weight: 500;
            margin-top: 31px;
          }
          .info {
            font-size: 20px;
            font-family: Medium;
            font-weight: 500;
            color: #9e9e9e;
            margin-top: 14px;
          }
          img {
            margin-top: -840px;
            position: relative;
            width: 88px;
          }
        }
      }
      .causecardinfo2 {
        margin-top: 115px;
        display: flex;
        justify-content: space-between;
        .causecard {
          width: 262px;
          height: 156px;
          box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.13);
          border-radius: 10px;
          border: 1px solid #e8e8e8;
          padding: 45px 85px;
          .title {
            font-size: 24px;
            font-family: Medium;
            font-weight: 500;
            margin-top: 31px;
          }
          .info {
            font-size: 20px;
            font-family: Medium;
            font-weight: 500;
            color: #9e9e9e;
            margin-top: 14px;
          }
          img {
            width: 88px;
            margin-top: -840px;
            position: relative;
          }
        }
      }
    }
  }

  .header {
    .title {
      font-size: 36px;
      font-family: Medium;
      font-weight: 500;
    }

    .info {
      font-size: 24px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #9e9e9e;
      margin-top: 31px;
    }
  }
}
</style>
